import React, {useState} from 'react';
import Auth from '../auth/Auth';
import {Link, Redirect} from 'react-router-dom';
import {toast} from 'react-toastify';
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";

function Login(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    if (Auth.isLoggedIn()) {
        return (<Redirect to="/"/>);
    }

    const login = (e) => {
        e.preventDefault();

        Auth.login(username, password, (callbackResponse) => {
            if (callbackResponse === 'success') {
                props.history.push('/');
                toast.success('Erfolgreich eingeloggt!')
            } else {
                if (callbackResponse === 'invalid_credentials') {
                    toast.error('Nutzername oder Passwort falsch!');
                } else {
                    toast.error(callbackResponse);
                }
            }
        });
    };

    return (
        <div className="container center bg-white shadow small-container">
            <div align="center">
                <h1>Video Manager</h1>
                <h3>Login</h3>
            </div>
            <br/>
            <Form onSubmit={login}>
                <Form.Group controlId="formUsername">
                    <Form.Label>
                        Nutzername
                    </Form.Label>
                    <Form.Control type="text" placeholder="Dein Nutzername" value={username}
                                  onChange={(e) => setUsername(e.target.value)} required/>
                </Form.Group>
                <Form.Group controlId="formPassword">
                    <Form.Label>
                        Passwort
                    </Form.Label>
                    <Form.Control type="password" placeholder="Dein Passwort" value={password}
                                  onChange={(e) => setPassword(e.target.value)} required/>
                </Form.Group>
                <div className="row" align="center">
                    <div className="col-sm">
                        <Button variant="primary" type="submit">Login</Button>
                    </div>
                    <div className="col-sm">
                        <Link to="/register">
                            <Button variant="danger" type="submit">Registrieren</Button>
                        </Link>
                    </div>
                </div>
            </Form>
            <br/>
            <div className="row" align="center">
                <div className="col-sm">
                    <p className="copyright">© {new Date().getFullYear()} | <a href="https://twitter.com/NotRedOre">RedOre</a></p>
                </div>
            </div>
        </div>
    );
}

export default Login;