import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import Auth from './auth/Auth';

const ProtectedRoute = ({component: Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={props =>
                Auth.isLoggedIn() ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login"
                        }}
                    />
                )
            }
        />
    );
};


export default ProtectedRoute;