import React, {useEffect, useState} from 'react';
import Navigation from './Navigation';
import {Button, Table} from "react-bootstrap";

import {Link} from "react-router-dom";
import Moment from 'react-moment';
import 'moment/locale/de';
import Auth from "../auth/Auth";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons';
import UpdateAlert from "./UpdateAlert";

function Index(props) {
    const [pages, setPages] = useState([]);

    useEffect(() => {
        Auth.get('pages')
            .then(response => {
                setPages(response.data.content);
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
    }, []);

    return (
        <div className="container-fluid">
            <Navigation/>
            <br/>
            <div className="container shadow bg-light">
                <br/>
                <UpdateAlert/>
                <br/>
                <h2>Pages</h2>
                <br/>
                <Table>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Titel</th>
                        <th>Einträge</th>
                        <th>Letzte Aktualisierung</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {pages.map(page => (
                        <tr key={page.id}>
                            <td className="id">{page.id}</td>
                            <td>{page.title}</td>
                            <td>{page.size}</td>
                            <td><Moment locale="de" fromNow>{page.lastUpdated}</Moment></td>
                            <td align="right">
                                <Link to={`/page/${page.id}`}><Button><FontAwesomeIcon
                                    icon={faSignInAlt}/></Button></Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <br/>
            </div>
        </div>
    );
}

export default Index;