import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {toast} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {GlobalRoutes} from "./util/Routes";

toast.configure({
    position: "bottom-right"
});

function App() {
    return (
        <>
            <Router>
                <GlobalRoutes/>
            </Router>
        </>
    );
}

export default App;
