import React from 'react';
import Auth from '../auth/Auth';
import {toast} from 'react-toastify';
import {useDispatch} from "react-redux";
import {logoutUser} from "../actions/user.actions";

function Logout(props) {

    const dispatch = useDispatch();

    Auth.logout((callbackResponse) => {
        if (callbackResponse === 'success' || callbackResponse === 'unauthorized') {
            toast.success('Logged out');
            props.history.push('/login');
            dispatch(logoutUser());
        }
    });
    return (<div>
        <p>Logging out...</p>
    </div>);
}

export default Logout;
