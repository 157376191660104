import React, {useState} from "react";
import Navigation from "./Navigation";
import Auth from "../auth/Auth";
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import {toast} from "react-toastify";
import ArrayUtil from "../util/ArrayUtil";
import {updateUserInfo} from "../services/UserService";
import {useDispatch, useSelector} from "react-redux";
import UpdateAlert from "./UpdateAlert";

function Me(props) {

    const user = useSelector(state => state.user);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showToken, setShowToken] = useState(false);

    const [inviteToken, setInviteToken] = useState('');

    const dispatch = useDispatch();

    let tokens = user.tokens || [];
    let removeTokens = [];
    let updating = false;

    const updateUsername = (e) => {
        setUsername(e.target.value);
    };
    const updatePassword = (e) => {
        setPassword(e.target.value);
    };

    const removeToken = (e, token) => {
        let checked = e.target.checked;
        if (checked) {
            removeTokens.push(token);
        } else {
            removeTokens = ArrayUtil.remove(removeTokens, token);
        }
    };

    const submit = (e) => {
        e.preventDefault();
        if (updating) {
            toast.error('Es läuft bereits eine Anfrage');
            return;
        }

        let data = {};
        if (username !== '') {
            data.username = username;
        }
        if (password !== '') {
            data.password = password;
        }
        if (removeTokens.length !== 0) {
            data.tokens = removeTokens;
        }
        if (ArrayUtil.getSize(data) === 0) {
            toast.error('Bitte füge mindestens eine Änderung hinzu')
            return;
        }
        updating = true;

        Auth.post('me/update', data)
            .then(response => {
                updating = false;
                updateUserInfo(dispatch, props);
                toast.success("Nutzerdaten aktualisiert");
            })
            .catch(error => {
                updating = false;
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
    };

    const inviteTokenSubmit = (e) => {
        e.preventDefault();

        Auth.post('join', JSON.stringify({token: inviteToken}))
            .then(response => {
                toast.success(`Page '${response.data.content.title}' beigetreten!`);
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
    };

    return (
        <div className="container-fluid">
            <Navigation/>
            <br/>
            <div className="container bg-white shadow">
                <br/>
                <UpdateAlert/>
                <br/>
                <h1>Nutzer Einstellungen</h1>
                <br/>
                <Form onSubmit={submit}>
                    <Form.Group controlId="formUsername">
                        <Form.Label>
                            Nutzername
                        </Form.Label>
                        <Form.Control type="text" placeholder="Neuer Nutzername" value={username}
                                      onChange={updateUsername}/>
                        <Form.Text>(Unverändert, wenn leer)</Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                        <Form.Label>
                            Passwort
                        </Form.Label>
                        <Form.Control type="password" placeholder="Neues Passwort" value={password}
                                      onChange={updatePassword}/>
                        <Form.Text>(Unverändert, wenn leer)</Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Tokens entfernen
                        </Form.Label>
                        {tokens.map(token => {
                            let classNames = "";
                            if (token === Auth.getToken()) {
                                classNames += "text-danger";
                            }
                            if (!showToken) {
                                classNames = "blur";
                            }

                            let displayToken = 'EnaBLEXshoWxtoKENsXtOXsEextoKenS';
                            if (showToken) {
                                displayToken = token;
                            }

                            let label = displayToken;
                            if (token === Auth.getToken()) {
                                label += " (Aktueller Token)";
                            }

                            return (
                                <Form.Check
                                    key={token}
                                    type="checkbox"
                                    className={classNames}
                                    label={label}
                                    onChange={(e) => {
                                        removeToken(e, token);
                                    }}/>
                            );
                        })}
                        <Form.Text>
                            (Ausgewählte Tokens werden entfernt, Der aktuelle Token sollte nicht entfernt werden)
                        </Form.Text>
                    </Form.Group>

                    <div className="row">
                        <div className="col-md-auto">
                            <Button variant="primary" type="submit">Update</Button>
                        </div>
                        <div className="col-md-auto">
                            <Button variant="danger" onClick={() => setShowToken(!showToken)}>
                                Tokens {showToken ? "ausblenden" : "einblenden"}
                            </Button>
                        </div>
                    </div>
                </Form>
                <br/>
            </div>
            <br/>
            <div className="container bg-white shadow">
                <br/>
                <h3>Token einlösen</h3>
                <br/>
                <Form onSubmit={inviteTokenSubmit}>
                    <Form.Group controlId="formToken">
                        <Form.Label>
                            Invite Token
                        </Form.Label>
                        <Form.Control required type="text" placeholder="Token" value={inviteToken}
                                      onChange={(e) => setInviteToken(e.target.value)}/>
                    </Form.Group>
                    <div className="row">
                        <div className="col-md-auto">
                            <Button variant="primary" type="submit">Einlösen</Button>
                        </div>
                    </div>
                </Form>
                <br/>
            </div>
            <br/>
            <br/>
        </div>
    );
}

export default Me;