import React, {useState} from "react";
import Auth from "../../auth/Auth";
import Navigation from "../Navigation";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import {Button, FormGroup, OverlayTrigger} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-bootstrap/Tooltip";
import Form from "react-bootstrap/Form";
import {toast} from 'react-toastify';
import PageUtil from "../../util/PageUtil";
import config from '../../config'
import {useSelector} from "react-redux";
import UpdateAlert from "../UpdateAlert";

function PageAdd(props) {

    const pageId = props.match.params.pageid;

    const [type, setType] = useState('youtube');
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [comment, setComment] = useState('');
    const [title, setTitle] = useState('');
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [color, setColor] = useState('gray');

    let running = false;

    const page = useSelector(state => state.page);

    const renderBackTooltip = (props) => {
        return <Tooltip {...props} show={props.show.toString()}>Zurück zur Page</Tooltip>;
    };

    const submit = (e) => {
        e.preventDefault();

        if(running){
            toast.error('Bitte warte kurz!');
            return;
        }

        running = true;
        if (type === 'youtube') {
            let data = {pageId: pageId, color: color, url: youtubeUrl};
            if (comment != null && comment !== '') {
                data.comment = comment;
            }
            Auth.post('page/item/add/youtube', JSON.stringify(data))
                .then(response => {
                    running = false;
                    toast.success('Der Eintrag wurde erfolgreich hinzugefügt!');
                    setComment('');
                    setYoutubeUrl('');
                })
                .catch(error => {
                    running = false;
                    let errorMessage = Auth.formatError(error);
                    Auth.handleDefault(errorMessage, props);
                });
        } else if (type === 'text') {
            let data = {pageId: pageId, color: color, title: title};
            if(comment != null && comment !== ''){
                data.comment = comment;
            }
            if(thumbnailUrl != null && thumbnailUrl !== ''){
                data.thumbnailUrl = thumbnailUrl;
            }

            Auth.post('page/item/add/text', JSON.stringify(data))
                .then(response => {
                    running = false;
                    toast.success('Der Eintrag wurde erfolgreich hinzugefügt!');
                    setComment('');
                    setThumbnailUrl('');
                    setTitle('');
                })
                .catch(error => {
                    running = false;
                    let errorMessage = Auth.formatError(error);
                    Auth.handleDefault(errorMessage, props);
                });
        } else {
            running = false;
        }
    };

    return (<div className="container-fluid">
        <Navigation/>
        <br/>
        <div className="container shadow bg-light">
            <br/>
            <UpdateAlert/>
            <br/>
            <h3>Video hinzufügen: {page.title}</h3>
            <div className="row">
                <div className="col">
                    <h5>Zuletzt Aktualisiert: <Moment fromNow interval={1000}>{page.lastUpdated}</Moment></h5>
                </div>
                <div align="right" className="col">

                    <Link to={`/page/${pageId}`}>
                        <OverlayTrigger placement="bottom" overlay={renderBackTooltip}>
                            <Button>
                                <FontAwesomeIcon icon={faArrowLeft}/>
                            </Button>
                        </OverlayTrigger>
                    </Link>
                </div>
            </div>
            <br/>
        </div>
        <br/>
        <div className="container shadow bg-light">
            <br/>
            <Form onSubmit={submit}>
                <FormGroup controlId="formType">
                    <Form.Label>
                        Type
                    </Form.Label>
                    <Form.Control
                        as="select"
                        value={type}
                        onChange={(e) => setType(e.target.value)}>
                        <option value="youtube">Youtube</option>
                        <option value="text">Text</option>
                    </Form.Control>
                </FormGroup>
                {type === 'youtube' &&
                <FormGroup controlId="formUrl">
                    <Form.Label>
                        Link
                    </Form.Label>
                    <Form.Control
                        type="url"
                        value={youtubeUrl}
                        onChange={(e) => setYoutubeUrl(e.target.value)}
                        required/>
                </FormGroup>
                }
                {type === 'text' &&
                <>
                    <FormGroup controlId="formTitle">
                        <Form.Label>
                            Titel
                        </Form.Label>
                        <Form.Control
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required/>
                    </FormGroup>
                    <FormGroup controlId="formThumbnailUrl">
                        <Form.Label>
                            Thumbnail Link
                        </Form.Label>
                        <Form.Control
                            type="url"
                            value={thumbnailUrl}
                            onChange={(e) => setThumbnailUrl(e.target.value)}
                        />
                        <Form.Text>(Optional, Link direkt zum Bild)</Form.Text>
                    </FormGroup>
                </>
                }
                <FormGroup controlId="formDescription">
                    <Form.Label>
                        Kommentar
                    </Form.Label>
                    <Form.Control value={comment} onChange={(e) => setComment(e.target.value)}/>
                    <Form.Text>(Optional)</Form.Text>
                </FormGroup>
                <FormGroup controlId="formColor">
                    <Form.Label>
                        Farbe
                    </Form.Label>
                    <Form.Control
                        as="select"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}>
                        {config.colors.map(color => (
                            <option key={color} style={{color: color}} value={color}>{PageUtil.formatName(page, color)}</option>
                        ))};
                    </Form.Control>
                </FormGroup>
                <br/>
                <div className="row">
                    <div className="col-md-auto">
                        <Button variant="primary" type="submit">Hinzufügen</Button>
                    </div>
                </div>
            </Form>
            <br/>
        </div>
        <br/>
        <br/>
    </div>);

}

export default PageAdd;