import Auth from "../auth/Auth";
import {updateUser} from "../actions/user.actions";
import {withRouter} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useEffect} from "react";

export const loadUserInfo = async () => {
    return Auth.get('me/info');
};

export const updateUserInfo = (dispatch, props) => {
    loadUserInfo()
        .then(response => {
            dispatch(updateUser(response.data.content));
        })
        .catch(error => {
            let errorMessage = Auth.formatError(error);
            Auth.handleDefault(errorMessage, props);
        });
};

export const UserLoader = withRouter((props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        updateUserInfo(dispatch, props);
    }, []);

    return (props.children);
});
