import {withRouter} from "react-router-dom";
import {Switch, Route} from 'react-router-dom';
import Login from "../components/Login";
import Register from "../components/Register";
import React from "react";
import {UserLoader} from "../services/UserService";
import ProtectedRoute from "../ProtectedRoute";
import Logout from "../components/Logout";
import Index from "../components/Index.js";
import Me from "../components/Me";
import {PageLoader} from "../services/PageService";
import Page from "../components/page/Page";
import PageEdit from "../components/page/PageEdit";
import PageLog from "../components/page/PageLog";
import PageAdd from "../components/page/PageAdd";
import AdminUser from "../components/admin/AdminUser";
import Admin from "../components/admin/Admin";
import NotFound from "../components/NotFound";

export const GlobalRoutes = withRouter(() => {
    return (
        <Switch>
            <Route
                exact
                path="/login"
                component={Login}
            />
            <Route
                exact
                path="/register"
                component={Register}
            />
            <Route
                path="*"
                component={LoggedInRoutes}
            />
        </Switch>
    )
});

export const LoggedInRoutes = withRouter(() => {
    return (
        <UserLoader>
            <Switch>
                <ProtectedRoute
                    exact
                    path="/logout"
                    component={Logout}
                />
                <ProtectedRoute exact path="/" component={Index}/>
                <ProtectedRoute exact path="/me" component={Me}/>
                <ProtectedRoute path="/page/:pageid" component={PageRoutes}/>
                <ProtectedRoute path="/admin" component={AdminRoutes}/>
                <Route path="*" component={NotFound}/>
            </Switch>
        </UserLoader>
    );
});

export const PageRoutes = withRouter(() => {
    return (
        <PageLoader>
            <Switch>
                <ProtectedRoute exact path="/page/:pageid" component={Page}/>
                <ProtectedRoute exact path="/page/:pageid/edit" component={PageEdit}/>
                <ProtectedRoute exact path="/page/:pageid/log/:site" component={PageLog}/>
                <ProtectedRoute exact path="/page/:pageid/add" component={PageAdd}/>
                <Route path="*" component={NotFound}/>
            </Switch>
        </PageLoader>
    );
});

export const AdminRoutes = withRouter(() => {
    return (
        <Switch>
            <ProtectedRoute exact path="/admin/user/:userid" component={AdminUser}/>
            <ProtectedRoute exact path="/admin" component={Admin}/>
            <Route path="*" component={NotFound}/>
        </Switch>
    );
});