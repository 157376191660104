import React, {useState} from "react";
import Auth from "../auth/Auth";
import {Link, Redirect} from "react-router-dom";
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import {toast} from "react-toastify";

const Register = (props) => {

    const [username, setusername] = useState('');
    const [password, setPassword] = useState('');
    const [registerToken, setRegisterToken] = useState('');

    if (Auth.isLoggedIn()) {
        return (<Redirect to="/"/>);
    }

    const submit = (e) => {
        e.preventDefault();

        let data = {username: username, password: password, token: registerToken};

        Auth.post('register', JSON.stringify(data), false)
            .then(response => {
                toast.success('Account erfolgreich erstellt.');

                Auth.login(username, password, (callbackResponse) => {
                    if (callbackResponse === 'success') {
                        props.history.push('/');
                    } else {
                        toast.error(callbackResponse);
                    }
                });
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
    };

    return (
        <div className="container center bg-white shadow small-container">
            <div align="center">
                <h1>Video Manager</h1>
                <h3>Registrieren</h3>
            </div>
            <br/>
            <Form onSubmit={submit}>
                <Form.Group controlId="formUsername">
                    <Form.Label>
                        Nutzername
                    </Form.Label>
                    <Form.Control type="text" placeholder="Dein Nutzername" value={username}
                                  onChange={(e) => setusername(e.target.value)} required/>
                </Form.Group>
                <Form.Group controlId="formPassword">
                    <Form.Label>
                        Passwort
                    </Form.Label>
                    <Form.Control type="password" placeholder="Dein Passwort" value={password}
                                  onChange={(e) => setPassword(e.target.value)} required/>
                </Form.Group>
                <Form.Group controlId="formRegisterToken">
                    <Form.Label>
                        Token
                    </Form.Label>
                    <Form.Control type="text" placeholder="Token" value={registerToken}
                                  onChange={(e) => setRegisterToken(e.target.value)} required/>
                </Form.Group>
                <div className="row" align="center">
                    <div className="col-sm">
                        <Button variant="primary" type="submit">Registrieren</Button>
                    </div>
                    <div className="col-sm">
                        <Link to="/login">
                            <Button variant="danger" type="submit">Zum Login</Button>
                        </Link>
                    </div>
                </div>
            </Form>
            <br/>
            <div className="row" align="center">
                <div className="col-sm">
                    <p className="copyright">© {new Date().getFullYear()} | <a href="https://twitter.com/NotRedOre">RedOre</a></p>
                </div>
            </div>
        </div>
    );
};

export default Register;