class ArrayUtil {

    getSize = (arr) => {
        var size = 0, key;
        for (key in arr) {
            if (arr.hasOwnProperty(key)) size++;
        }
        return size;
    };

     remove = (arr, value) => {
        return arr.filter(function (ele) {
            return ele !== value;
        });
    };

}

export default new ArrayUtil();