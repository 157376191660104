import React, {useEffect, useState} from "react";
import Auth from '../../auth/Auth';
import {toast} from "react-toastify";
import Navigation from "../Navigation";
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import ArrayUtil from "../../util/ArrayUtil";
import UpdateAlert from "../UpdateAlert";

function AdminUser(props) {

    const userId = props.match.params.userid;

    const [targetUser, setTargetUser] = useState({});
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [resetTokens, setResetTokens] = useState(false);
    const [admin, setAdmin] = useState('none');

    let updating = false;

    useEffect(() => {
        updateTargetUser();
    }, []);

    const updateTargetUser = () => {
        Auth.get(`admin/user/${userId}`)
            .then(response => {
                setTargetUser(response.data.content);
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
    };

    const submit = (e) => {
        e.preventDefault();

        if (updating) {
            toast.error('Es läuft bereits eine Anfrage');
            return;
        }

        let data = {};
        if (username !== '') {
            data.username = username;
        }
        if (password !== '') {
            data.password = password;
        }
        if (admin !== 'none') {
            if (admin === 'grant') {
                data.admin = true;
            } else if (admin === 'deny') {
                data.admin = false;
            }
        }
        if (resetTokens) {
            data.tokens = resetTokens;
        }
        if (ArrayUtil.getSize(data) === 0) {
            toast.error('Bitte füge mindestens eine Änderung hinzu')
            return;
        }

        data.userId = targetUser.id;
        updating = true;

        Auth.post('admin/user/edit', data)
            .then(response => {
                updating = false;
                updateTargetUser();
                toast.success("Nutzerdaten aktualisiert");
            })
            .catch(error => {
                updating = false;
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
    };

    return (
        <div className="container-fluid">
            <Navigation/>
            <br/>
            <div className="container shadow bg-white">
                <br/>
                <UpdateAlert/>
                <br/>
                <h2>Nutzer editieren: {targetUser.username} {targetUser.admin ? '(Admin)' : ''}</h2>
                <br/>
            </div>
            <br/>
            <div className="container shadow bg-white">
                <br/>

                <Form onSubmit={submit}>
                    <Form.Group controlId="formUsername">
                        <Form.Label>
                            Nutzername
                        </Form.Label>
                        <Form.Control type="text" placeholder="Neuer Nutzername" value={username}
                                      onChange={(e) => setUsername(e.target.value)}/>
                        <Form.Text>(Unverändert, wenn leer)</Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                        <Form.Label>
                            Passwort
                        </Form.Label>
                        <Form.Control type="password" placeholder="Neues Passwort" value={password}
                                      onChange={(e) => setPassword(e.target.value)}/>
                        <Form.Text>(Unverändert, wenn leer)</Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Admin Zugriff
                        </Form.Label>
                        <Form.Control
                            as="select"
                            value={admin}
                            onChange={(e) => setAdmin(e.target.value)}>
                            <option value="none">Unverändert</option>
                            <option value="grant">Admin Zugriff</option>
                            <option value="deny">Kein Admin Zugriff</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            value={resetTokens}
                            onChange={(e) => setResetTokens(e.target.checked)}
                            type="checkbox"
                            label="Alle Tokens zurücksetzen"
                        />
                    </Form.Group>

                    <div className="row">
                        <div className="col-md-auto">
                            <Button variant="primary" type="submit">Update</Button>
                        </div>
                    </div>
                </Form>
                <br/>
            </div>
        </div>
    )

}

export default AdminUser;