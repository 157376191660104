import React, {useEffect, useState} from "react";
import Auth from "../../auth/Auth";
import Navigation from "../Navigation";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import {Button, FormGroup, OverlayTrigger, Table} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faTrash, faUserEdit} from '@fortawesome/free-solid-svg-icons';
import Form from "react-bootstrap/Form";
import {toast} from "react-toastify";
import ArrayUtil from "../../util/ArrayUtil";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal";
import PageUtil from "../../util/PageUtil";
import config from '../../config'
import {useDispatch, useSelector} from "react-redux";
import {updatePageInfo} from "../../services/PageService";
import {updatePage} from "../../actions/page.actions";
import UpdateAlert from "../UpdateAlert";

function PageEdit(props) {

    const pageId = props.match.params.pageid;

    const user = useSelector(state => state.user);

    const page = useSelector(state => state.page);

    const [title, setTitle] = useState('');
    const [colorMapping, setColorMapping] = useState({});
    const [showToken, setShowToken] = useState(false);
    const [inviteTokens, setInviteTokens] = useState([]);
    const [kickModal, setKickModal] = useState({show: false, user: {}});
    const [editModal, setEditModal] = useState({show: false, user: {}});
    const [permissions, setPermissions] = useState([]);

    let updating = false;

    const dispatch = useDispatch();


    useEffect(() => {
        setTitle(page.title);
        setColorMapping(page.colorMapping);
    }, [page]);

    useEffect(() => {
        if (hasPermission('invite')) {
            Auth.get(`page/invitetoken/list/${pageId}`)
                .then(response => {
                    setInviteTokens(response.data.content);
                })
                .catch(error => {
                    let errorMessage = Auth.formatError(error);
                    Auth.handleDefault(errorMessage, props);
                });
        }
    }, [user]);

    const loadPageInfo = () => {
        updatePageInfo(page.id, dispatch, props);
    };

    const submitPage = (e) => {
        e.preventDefault();

        Auth.post('page/edit', JSON.stringify({pageId: pageId, title: title, colorMapping: colorMapping}))
            .then(response => {
                loadPageInfo();
                toast.success('Page geändert');
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
    };

    const hasPermission = (permission) => {
        return page.permissions.includes(permission) || user.admin;
    };

    const createInviteToken = () => {
        if (updating) {
            toast.error('Bitte warte kurz!');
            return;
        }
        updating = true;
        Auth.post('page/invitetoken/create', JSON.stringify({pageId: pageId}))
            .then(response => {
                let token = response.data.content;
                let newTokens = [...inviteTokens];
                newTokens.push(token);
                setInviteTokens(newTokens);
                toast.success('Invite Token hinzugefügt');
                updating = false;
            })
            .catch(error => {
                updating = false;
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
    };

    const deleteInviteToken = (inviteToken) => {
        Auth.delete('page/invitetoken/delete', JSON.stringify({pageId: pageId, inviteToken: inviteToken}))
            .then(response => {
                setInviteTokens(ArrayUtil.remove(inviteTokens, inviteToken));
                toast.success('Invite Token gelöscht');
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
    };

    const renderBackTooltip = (props) => {
        return <Tooltip {...props} show={props.show.toString()}>Zurück zur Page</Tooltip>;
    };

    const renderPermsTooltip = (props) => {
        return <Tooltip {...props} show={props.show.toString()}>Nutzerrechte anpassen</Tooltip>;
    };

    const renderKickTooltip = (props) => {
        return <Tooltip {...props} show={props.show.toString()}>Nutzer entfernen</Tooltip>;
    };

    const kickUser = () => {
        let targetUser = kickModal.user;
        Auth.delete('page/kick', JSON.stringify({pageId: page.id, userId: targetUser.id}))
            .then(response => {
                let newPage = {...page};
                newPage.users = ArrayUtil.remove(newPage.users, targetUser);
                dispatch(updatePage(newPage));
                setKickModal({show: false, user: {}});

                toast.success('Nutzer entfernt');
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
                setKickModal({show: false, user: {}});
            });
    };

    const togglePermission = (permission, checked) => {
        if (checked) {
            if (!permissions.includes(permission)) {
                let newPermissions = [...permissions];
                newPermissions.push(permission);
                setPermissions(newPermissions);
            }
        } else {
            if (permissions.includes(permission)) {
                let newPermissions = ArrayUtil.remove(permissions, permission);
                setPermissions(newPermissions);
            }
        }
    };

    const selectEdit = (selectUser) => {
        setEditModal({show: true, user: selectUser});
        setPermissions(selectUser.permissions);
    };

    const editPermissions = () => {
        let targetUser = editModal.user;
        Auth.post('page/permissions/update', JSON.stringify({
            pageId: page.id,
            userId: targetUser.id,
            permissions: permissions
        }))
            .then(response => {
                loadPageInfo();
                setEditModal({show: false, user: {}});
                toast.success('Nutzerrechte angepasst');
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
                setEditModal({show: false, user: {}});
            });
    };

    const updateColorMapping = (color, newValue) => {
        console.log(colorMapping);
        let newMapping = {...colorMapping};

        newMapping[color] = newValue;
        setColorMapping(newMapping);
        console.log(newMapping);
    };

    const getColorMapping = (color) => {
        if (!colorMapping.hasOwnProperty(color)) {
            return '';
        }
        return colorMapping[color];
    };

    return (
        <div className="container-fluid">
            <Navigation/>
            <br/>
            <div className="container shadow bg-light">
                <br/>
                <UpdateAlert/>
                <br/>
                <h3>Editieren: {page.title}</h3>
                <div className="row">
                    <div className="col">
                        <h5>Zuletzt Aktualisiert: <Moment fromNow interval={1000}>{page.lastUpdated}</Moment></h5>
                    </div>
                    <div align="right" className="col">

                        <Link to={`/page/${pageId}`}>
                            <OverlayTrigger placement="bottom" overlay={renderBackTooltip}>
                                <Button>
                                    <FontAwesomeIcon icon={faArrowLeft}/>
                                </Button>
                            </OverlayTrigger>
                        </Link>
                    </div>
                </div>
                <br/>
            </div>
            <br/>
            <div className="container shadow bg-light">
                <br/>
                <h4>Mitglieder</h4>
                <br/>

                <Table>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Username</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {page.users.map(user => {
                        return (
                            <tr key={user.id}>
                                <td className="id">{user.id}</td>
                                <td>{user.username}</td>
                                <td>
                                    <div className="row float-right">
                                        {hasPermission('perms') &&
                                        <div className="col-md-auto">

                                            <OverlayTrigger placement="bottom" overlay={renderPermsTooltip}>
                                                <Button onClick={() => selectEdit(user)}>
                                                    <FontAwesomeIcon icon={faUserEdit}/>
                                                </Button>
                                            </OverlayTrigger>
                                        </div>
                                        }
                                        {hasPermission('kick') &&
                                        <div className="col-md-auto">
                                            <OverlayTrigger placement="bottom" overlay={renderKickTooltip}>
                                                <Button variant="danger"
                                                        onClick={() => setKickModal({show: true, user: user})}>
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </Button>
                                            </OverlayTrigger>
                                        </div>
                                        }
                                    </div>
                                </td>
                            </tr>
                        );

                    })}
                    </tbody>
                </Table>
                <br/>
            </div>
            {hasPermission('editpage') &&
            <>
                <br/>
                <div className="container shadow bg-light">
                    <br/>
                    <h4>Page editieren</h4>
                    <br/>
                    <Form onSubmit={submitPage}>
                        <Form.Group controlId="formTitle">
                            <Form.Label>
                                Titel
                            </Form.Label>
                            <Form.Control required type="text" placeholder="Neuer Titel" value={title}
                                          onChange={(e) => setTitle(e.target.value)}/>
                        </Form.Group>
                        {config.colors.map(color => {
                            return (
                                <Form.Group controlId={color} key={color}>
                                    <Form.Label>
                                        {PageUtil.formatName(page, color)}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Color Mapping"
                                        value={getColorMapping(color)}
                                        onChange={(e) => updateColorMapping(color, e.target.value)}/>
                                </Form.Group>
                            )
                        })}

                        <div className="row">
                            <div className="col-md-auto">
                                <Button variant="primary" type="submit">Update</Button>
                            </div>
                        </div>
                    </Form>
                    <br/>
                </div>
            </>
            }
            {hasPermission('invite') &&
            <>
                <br/>
                <div className="container shadow bg-light">
                    <br/>
                    <h4>Invite Tokens</h4>
                    <div className="row">
                        <div className="col-md-auto">
                            <Button variant="warning" onClick={createInviteToken}>Neuer Token erstellen</Button>
                        </div>
                        <div className="col-md-auto">
                            <Button variant="danger" onClick={() => setShowToken(!showToken)}>
                                Tokens {showToken ? "ausblenden" : "einblenden"}
                            </Button>
                        </div>
                    </div>
                    <br/>
                    <Table>
                        <thead>
                        <tr>
                            <th>Token</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {inviteTokens.map(inviteToken => {
                            let classNames = "";
                            if (!showToken) {
                                classNames = "blur";
                            }
                            let displayToken = 'XTOKENSX';
                            if (showToken) {
                                displayToken = inviteToken;
                            }

                            return (
                                <tr key={inviteToken}>
                                    <td className={classNames}>{displayToken}</td>
                                    <td align="right">
                                        <Button variant="danger" onClick={() => {
                                            deleteInviteToken(inviteToken);
                                        }}>
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </Button>
                                    </td>
                                </tr>
                            );

                        })}
                        </tbody>
                    </Table>
                    <br/>
                </div>
            </>
            }
            <br/>
            <br/>
            <Modal show={kickModal.show} onHide={() => setKickModal({show: false, user: {}})}>
                <Modal.Header closeButton>
                    <Modal.Title>Nutzer entfernen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-danger">Möchtest du wirklich den Nutzer "{kickModal.user.username}"
                        entfernen?</p>
                    {kickModal.user.id === user.id &&
                    <p className="text-danger">Du kannst nicht deinen eigenen Benutzer löschen!</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={kickUser}>Nutzer entfernen</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={editModal.show} onHide={() => setEditModal({show: false, user: {}})}>
                <Modal.Header closeButton>
                    <Modal.Title>Nutzerrechte anpassen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Username: {editModal.user.username}</h4>
                    <br/>
                    {editModal.user.id === user.id &&
                    <p className="text-danger">Du kannst nicht deinen eigenen Benutzer anpassen!</p>
                    }
                    <Form>
                        <FormGroup controlId="formPerms">
                            {config.permissions.map(permission => {
                                return (
                                    <Form.Check
                                        key={permission}
                                        id={permission}
                                        checked={(permissions.includes(permission))}
                                        onChange={(e) => togglePermission(permission, e.target.checked)}
                                        type="checkbox"
                                        label={permission}
                                    />
                                );
                            })}
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={editPermissions}>Nutzerrechte anpassen</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

}

export default PageEdit;
