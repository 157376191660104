import React, {useEffect, useState} from 'react';
import Navigation from './Navigation';
import {Alert, Button, Table} from "react-bootstrap";

import {Link} from "react-router-dom";
import Moment from 'react-moment';
import 'moment/locale/de';
import Auth from "../auth/Auth";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons';
import {useSelector} from "react-redux";

function Index(props) {

    const user = useSelector(state => state.user);

    return (
        <></>
        // <Alert variant="info">
        //     <Alert.Heading>Hallo {user.username}!</Alert.Heading>
        //     <p>
        //         Ich arbeite aktuell an einer neuen Version des VideoManagers und würde gerne auf deine Wünsche eingehen.<br/>
        //         Hast du Verbesserungen, Vorschläge oder einfach Ideen? Dann schreib mir auf Twitter <a href="https://twitter.com/NotRedOre">@NotRedOre</a>.
        //     </p>
        // </Alert>
    );
}

export default Index;